
// Update in src/components/QRCodeGenerator.tsx
import React, { useState, useRef } from 'react';
import {
    TextField, Button, Typography, Container, Box, Snackbar,
    Alert,
} from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import logo from '../assets/qrixy-logo.png';
import MenuBar from './MenuBar';
import { isMobile } from 'react-device-detect';
import InformationSection from './InformationSection';
import useUrlValidator from '../customHooks/useUrlValidator';
import { Switch, FormControlLabel } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ColorPickerComponent from './base/ColorPicker';
import DonationSection from './DonationSection';

const QRCodeGenerator: React.FC = () => {
    const [url, setUrl] = useState<string>('');
    const qrRef = useRef<HTMLDivElement>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [isProMode, setIsProMode] = useState<boolean>(false);
    const [qrColor, setQrColor] = useState<string>('#000000'); // Default is black
    const [logoImage, setLogoImage] = useState<string | null>(null);

    const { isValidUrl } = useUrlValidator();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(e.target.value);
    };

    const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsProMode(event.target.checked);
        if (!event.target.checked) {
            setQrColor('#000000');
            setLogoImage(null);
        }
    };

    const handleColorChange = (color: string) => {
        setQrColor(color);
    };

    const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setLogoImage(e.target?.result as string);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleDownload = () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                const pngUrl = canvas
                    .toDataURL('image/png');
                const downloadLink = document.createElement('a');
                downloadLink.href = pngUrl;
                downloadLink.download = 'qr-by-qrixy.png';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setSnackbarMessage('QR Code downloaded.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            }
        }
    };

    const handleShare = async () => {
        if (qrRef.current) {
            const canvas = qrRef.current.querySelector('canvas');
            if (canvas) {
                canvas.toBlob(async (blob) => {
                    if (blob) {
                        const filesArray = [new File([blob], 'qr-by-qrixy.png', { type: 'image/png' })];
                        const shareData = {
                            files: filesArray,
                            title: 'QR code by QRrixy',
                            text: 'Hey. I just created this QR code using Qrixy. Check it out!',
                        };

                        try {
                            if (navigator.canShare && navigator.canShare({ files: filesArray })) {
                                await navigator.share(shareData);
                                setSnackbarMessage('QR Code shared successfully.');
                                setSnackbarSeverity('success');
                                setSnackbarOpen(true);
                            } else {
                                setSnackbarMessage('Sharing is not supported on this device.');
                                setSnackbarSeverity('error');
                                setSnackbarOpen(true);
                            }
                        } catch (error) {
                            ;
                        }
                    }
                }, 'image/png');
            }
        }
    };

    return (
        <>
            {/* Use MenuBar component */}
            <MenuBar type="home" />
            <Container maxWidth="sm" style={{ textAlign: 'center', color: '#FFFFFF' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 3,
                    }}
                >                    <Box
                        component="img"
                        src={logo}
                        alt="Qrixy Logo"
                        sx={{ width: 50, height: 'auto', mr: 2 }}
                    />
                    <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                        Qrixy - URL to QR Code
                    </Typography>
                </Box>
                <Typography
                    variant="subtitle1"
                    sx={{ color: '#FFFFFF', mb: 3 }}
                >
                    Enter a URL below to generate a QR code that you can share with others.
                </Typography>
                <TextField
                    fullWidth
                    label="Enter URL"
                    variant="outlined"
                    value={url}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                        mb: 2, input: { color: '#FFFFFF' },
                        label: { color: '#FFFFFF' },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#FFFFFF',
                            },
                            '&:hover fieldset': {
                                borderColor: '#FFFFFF',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#FFFFFF',
                            },
                        },
                        '& .MuiFormHelperText-root': {
                            color: '#FFFFFF',
                        },
                    }}
                />
                {url && !isValidUrl(url) && (
                    <Typography color="error">Please enter a valid URL.</Typography>
                )}
                <FormControlLabel
                    control={
                        <Switch
                            checked={isProMode}
                            onChange={handleModeChange}
                            color="primary"
                        />
                    }
                    label="Enable pro mode"
                    sx={{ color: '#FFFFFF' }}
                />
                {isProMode && (
                    <Box sx={{ display: 'inline-flex', flexDirection: 'row', gap: 5 }}>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                                Choose QR Code Color:
                            </Typography>
                            <ColorPickerComponent color={qrColor} onColorChange={handleColorChange} />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                                Upload Logo:
                            </Typography>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={<FileUploadOutlinedIcon />}
                            >
                                Choose File
                                <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handleLogoUpload}
                                />
                            </Button>
                            {logoImage && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF', mb: 1 }}>
                                        Logo Preview:
                                    </Typography>
                                    <Box
                                        component="img"
                                        src={logoImage}
                                        alt="Uploaded Logo"
                                        sx={{ maxHeight: 100 }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
                {url && isValidUrl(url) && (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 5,
                    }} ref={qrRef}>
                        <Box
                            sx={{
                                display: 'inline-block',
                                border: '2px solid #FFFFFF',
                                borderRadius: 2,
                                backgroundColor: '#FFFFFF',
                            }}
                        >
                            <QRCode value={url} size={(isMobile ? 256 : 512)} fgColor={qrColor} logoImage={logoImage ? logoImage : undefined} logoWidth={logoImage ? (isMobile ? 64 : 128) : undefined} logoHeight={logoImage ? (isMobile ? 64 : 128) : undefined} />
                        </Box>
                        <Container maxWidth="sm" sx={{ mt: 2, display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                            {!isMobile && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDownload}
                                    sx={{
                                        mt: 2,
                                        color: '#4A148C',
                                        '&:hover': {
                                            backgroundColor: '#F3E5F5',
                                        },
                                    }}
                                    startIcon={<FileDownloadOutlinedIcon />}
                                >
                                    Download
                                </Button>)}
                            {isMobile && (<Button
                                variant="contained"
                                color='primary'
                                onClick={handleShare}
                                sx={{
                                    mt: 2,
                                    backgroundColor: '#FFFFFF',
                                    color: '#4A148C',
                                    '&:hover': {
                                        backgroundColor: '#F3E5F5',
                                    },
                                }}
                                startIcon={<ShareOutlinedIcon />}
                            >
                                Share
                            </Button>)}
                        </Container>
                    </Box>
                )}
            </Container >
            <InformationSection />
            <DonationSection />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar></>
    );
};

export default QRCodeGenerator;