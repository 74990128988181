// src/App.tsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import QRCodeGenerator from './components/QRCodeGenerator';
import DataPrivacy from './components/DataPrivacy';
import Impressum from './components/Impressum';
import { initGA, logPageView } from './analytics';
import Faq from './components/Faq';
import BlogList from './components/BlogList';

const App: React.FC = () => {
  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  return (
    <Router>
      <Box
        sx={{
          minHeight: '100vh',
          background: 'linear-gradient(to bottom, #4A148C, #CE93D8)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Routes>
          <Route path="/" element={<QRCodeGenerator />} />
          <Route path="/privacy" element={<DataPrivacy />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/blog" element={<BlogList />} />
        </Routes>
      </Box>
    </Router >
  );
};

export default App;