// src/components/BlogList.tsx
import React from 'react';
import blogData from '../assets/blog.json';
import BlogPost from './base/BlogPost';
import { Container, Typography } from '@mui/material';
import MenuBar from './MenuBar';

interface BlogPostData {
    title: string;
    datetime: string;
    content: string;
}

const BlogList: React.FC = () => {
    const posts: BlogPostData[] = blogData.posts;

    // Sort posts by datetime in descending order (newest first)
    const sortedPosts = posts.sort(
        (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
    );

    return (
        <>
            <MenuBar type='blog' />
            <Container maxWidth="sm" sx={{ py: 4 }}>
                <Typography variant="h4" component="h1" align="center" gutterBottom sx={{ color: '#FFFFFF', pb: "30px" }}>
                    Latest Updates from Qrixy
                </Typography>
                {sortedPosts.map((post, index) => (
                    <BlogPost key={index} post={post} />
                ))}
            </Container>
        </>
    );
};

export default BlogList;