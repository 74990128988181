import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent: React.FC = () => {
    return (
        <Helmet>
            <title>Qrixy - Convert URLs to QR Codes instantly</title>
            <link rel="canonical" href="https://www.qrixy.me" />
            <meta
                name="description"
                content="Qrixy is an application that converts URLs into customizable QR codes for easy sharing." />
            <meta name="keywords" content="QRixy, QR Code, URL, Converter, Sharing, Custom, Logo, Color" />
            <meta name="author" content="Stephan Hölscher" />

            <meta property="og:title" content="Qrixy - URL to QR Code Converter" />
            <meta
                property="og:description"
                content="Convert any URL into a QR code instantly with Qrixy."
            />
            <meta property="og:image" content="%PUBLIC_URL%/og-image.png" />
            <meta property="og:url" content="https://qrixy.me" />
            <meta property="og:type" content="website" />

            <meta name="twitter:title" content="Qrixy - URL to QR Code Converter" />
            <meta
                name="twitter:description"
                content="Convert any URL into a QR code instantly with Qrixy."
            />
            <meta name="twitter:image" content="%PUBLIC_URL%/twitter-image.png" />

            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
    );
};

export default HelmetComponent;