// src/components/DataPrivacy.tsx
import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import MenuBar from './MenuBar';
import { Helmet } from 'react-helmet';

const DataPrivacy: React.FC = () => {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.quirxy.me/privacy" />
            </Helmet>
            {/* Use MenuBar component */}
            <MenuBar type="privacy" />
            <Container maxWidth="sm" sx={{ py: 4, color: '#FFFFFF' }}>
                <Box sx={{ backgroundColor: 'transparent', border: '1px solid #FFFFFF', p: 4, borderRadius: 2 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Data Privacy Policy
                    </Typography>
                    <Typography variant="body1" sx={{ paddingBottom: '20px', }}>
                        At Qrixy, we value your privacy. This application does not store any information about the URLs you enter or any personal data from users. All QR code generation is performed locally in your browser, ensuring that your data remains private and secure.
                    </Typography>
                    <Typography variant="body1" sx={{ paddingBottom: '20px', }}>
                        This application uses Google Analytics to collect anonymous data for performance monitoring and reachability measurement. Google Analytics helps us understand how users interact with our site so we can improve the user experience.
                    </Typography>
                    <Typography variant="body1" sx={{ paddingBottom: '20px', }}>
                        The data collected by Google Analytics includes information such as your IP address, browser type, operating system, referring URLs, and pages visited on our site. This information is used solely for analytical purposes and is not linked to any personally identifiable information.
                    </Typography>
                    <Typography variant="body1" sx={{ paddingBottom: '20px', }}>
                        By using Qrixy, you consent to the processing of data about you by Google in the manner and for the purposes set out above. You can opt-out of Google Analytics by installing the <a href="https://tools.google.com/dlpage/gaoptout" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>Google Analytics Opt-out Browser Add-on</a>.
                    </Typography>
                    <Typography variant="body1">
                        If you have any questions or concerns about our data privacy practices, please feel free to contact us.
                    </Typography>
                </Box>
            </Container>
        </>
    );
};

export default DataPrivacy;