// src/components/CustomLink.tsx
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface CustomLinkProps {
    to: string;
    text?: string;
    external?: boolean;
    icon?: React.ReactNode;
    border?: boolean;
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, text, external = false, icon, border = false }) => {
    return (
        <Link
            component={external ? 'a' : RouterLink}
            to={external ? undefined : to}
            href={external ? to : undefined}
            target={external ? '_blank' : '_self'}
            rel={external ? 'noopener noreferrer' : undefined}
            underline="none"
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                verticalAlign: 'middle',
                color: '#FFFFFF',
                '&:hover': {
                    color: '#F3E5F5',
                },
                ...(border && { border: '1px solid #FFFFFF', borderRadius: '10px', padding: '5px' }),
            }}
        >
            {icon && (
                <span style={{ display: 'flex', alignItems: 'center', marginRight: 4 }}>
                    {icon}
                </span>
            )}
            {text && <Typography sx={{ typography: { ml: 'body2', sm: 'body1' } }}>{text}</Typography>}
            {external && (
                <OpenInNewIcon sx={{ fontSize: '1rem', marginLeft: '4px' }} />
            )}
        </Link>
    );
};

export default CustomLink;