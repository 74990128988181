import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
    backgroundColor: 'transparent',
    boxShadow: 'none',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const InfoAccordion: React.FC = () => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>Do Qrixy QR Codes Expire?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                        QR codes generated through Qrixy do not expire. Once created, they remain active indefinitely unless manually removed. This ensures that your QR codes are always accessible and functional for your intended use.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>Are Qrixy QR Codes Branded?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                        Currently, Qrixy offers standard QR codes without branding. This especially means that the downloaded QR code does not contain any branding from Qrixy itself.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>Is Qrixy Free to Use?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                        Yes, Qrixy is completely free to use! You can generate as many QR codes as you like without any charges or hidden fees. We believe in providing accessible tools to help you share URLs quickly and easily.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>Do I Need to Sign Up or Create an Account?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                        Nope! Qrixy does not require any sign-up or account creation. Just head over to <a href="https://www.qrixy.me" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>qrixy.me</a>, enter the URL you want to share, and your QR code will be generated instantly. It is all about keeping things simple and hassle-free.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>How Secure is My Data with Qrixy?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                        Your privacy is super important to us. Qrixy does not store or track any of the URLs you input. All QR code generation happens locally in your browser, ensuring that your data stays with you and is not shared with anyone else.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>Can I Customize the Design of the QR Codes?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                        You can use the pro mode to customize the design of your QR codes. This includes changing the color and adding a logo. The pro mode is available for everybody and free to use.  More features will be added in future.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>Can I buy you a coffee?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle1" sx={{ color: '#FFFFFF' }}>
                        Sure. There is a donation link on the main page. You can buy me a coffee there using PayPal. Thank you very much.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );

};

export default InfoAccordion;