import React from 'react';
import { HexColorPicker } from "react-colorful";

interface ColorPickerComponentProps {
    color: string;
    onColorChange: (newColor: string) => void;
}

const ColorPickerComponent: React.FC<ColorPickerComponentProps> = ({ color, onColorChange }) => {
    return (
        <div>
            <HexColorPicker color={color} onChange={onColorChange} />
        </div>
    );
};

export default ColorPickerComponent;