import { Box, Container } from '@mui/material';
import React from 'react';
import CustomLink from './base/CustomLink';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import { useViewport } from '../customHooks/useViewport';

const DonationSection: React.FC = () => {

    const { width } = useViewport();
    const breakpoint = 600; // Set your desired breakpoint here

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', color: '#FFFFFF' }}>
            <Box sx={{
                mt: 5, mb: 2
            }} >
                <CustomLink
                    to="https://www.paypal.com/donate/?hosted_button_id=D47D9J7NLMX9Q"
                    text={width >= breakpoint ? "You like it? Buy me a coffee. ☕️" : "Like? ☕️"}
                    icon={<VolunteerActivismOutlinedIcon />}
                    external={true}
                    border={true}
                />
            </Box>
        </Container >
    );
};

export default DonationSection;