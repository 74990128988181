// utils/useViewport.ts
import { useState, useEffect } from "react";

interface WindowDimensions {
  width: number;
  height: number;
}

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export function useViewport(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    () => {
      if (typeof window !== "undefined") {
        return getWindowDimensions();
      } else {
        // Default values for server-side rendering
        return { width: 0, height: 0 };
      }
    }
  );

  useEffect(() => {
    if (typeof window === "undefined") return; // Exit early on server

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
