// src/components/MenuBar.tsx
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    MenuItem,
} from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';

interface MenuBarProps {
    type: 'home' | 'privacy' | 'impressum' | 'faq' | 'blog';
}

// Custom styled components
const CustomMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        {...props}
        MenuListProps={{ disablePadding: true }}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: 'transparent', // Semi-transparent background
        color: '#FFFFFF',
        border: '1px solid #FFFFFF',
        backdropFilter: 'blur(5px)', // Optional: adds a blur effect
    },
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    color: '#FFFFFF',
    borderBottom: '1px solid #FFFFFF',
    '&:last-child': {
        borderBottom: 'none',
    },
    '&.MuiMenuItem-root': {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
}));

const MenuBar: React.FC<MenuBarProps> = ({ type }) => {
    const navigate = useNavigate();

    // For the menu in the home page
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePrivacyClick = () => {
        navigate('/privacy');
        setAnchorEl(null);
    };

    const handleImpressumClick = () => {
        navigate('/impressum');
        setAnchorEl(null);
    };

    const handleFaqClick = () => {
        navigate('/faq');
        setAnchorEl(null);
    };

    const handleBlogClick = () => {
        navigate('/blog');
        setAnchorEl(null);
    };

    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <AppBar
            position="static"
            sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
        >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {type === 'home' && (
                    <IconButton
                        size="large"
                        edge="start"
                        color="primary"
                        aria-label="info"
                        aria-controls="info-menu"
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                    >
                        <MenuOutlinedIcon />
                    </IconButton>
                )}
                {(type === 'privacy' || type === 'impressum' || type === 'faq' || type === 'blog') && (
                    <IconButton
                        edge="start"
                        color="primary"
                        onClick={handleBackClick}
                        aria-label="back"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <div /> {/* Placeholder to push any content to the left */}
            </Toolbar>
            {type === 'home' && (
                <CustomMenu
                    id="info-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <CustomMenuItem onClick={handleFaqClick}>
                        <LiveHelpOutlinedIcon sx={{ mr: 1 }} />
                        FAQ
                    </CustomMenuItem>
                    <CustomMenuItem onClick={handleBlogClick}>
                        <BookOutlinedIcon sx={{ mr: 2 }} />
                        Blog
                    </CustomMenuItem>
                    <CustomMenuItem onClick={handlePrivacyClick}>
                        <PrivacyTipOutlinedIcon sx={{ mr: 1 }} />
                        Data Privacy
                    </CustomMenuItem>
                    <CustomMenuItem onClick={handleImpressumClick}>
                        <CopyrightOutlinedIcon sx={{ mr: 2 }} />
                        Impressum
                    </CustomMenuItem>
                </CustomMenu>
            )}
        </AppBar>
    );
};

export default MenuBar;