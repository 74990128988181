// src/hooks/useUrlValidator.ts
import { useCallback } from "react";

const useUrlValidator = () => {
  const isValidUrl = useCallback((urlString: string): boolean => {
    const urlRegex = new RegExp(
      "^" +
        // Protocol identifier (optional)
        "(?:(?:https?|ftp)://)?" +
        // Authentication (optional)
        "(?:\\S+(?::\\S*)?@)?" +
        "(?:" +
        // IP address (v4)
        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
        "(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-5]))" +
        "|" +
        // Host name
        "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
        // Domain name
        "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
        // TLD identifier
        "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
        ")" +
        // Port number (optional)
        "(?::\\d{2,5})?" +
        // Resource path (optional)
        "(?:/[^\\s]*)?" +
        "$",
      "i"
    );
    return urlRegex.test(urlString);
  }, []);

  return { isValidUrl };
};

export default useUrlValidator;
