import { Box, Container } from '@mui/material';
import React from 'react';
import CustomLink from './base/CustomLink';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { useViewport } from '../customHooks/useViewport';

const InformationSection: React.FC = () => {

    const { width } = useViewport();
    const breakpoint = 600; // Set your desired breakpoint here

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', color: '#FFFFFF' }}>
            <Box sx={{ mt: 5 }}>
                <CustomLink
                    to="/faq"
                    text={width >= breakpoint ? "Got questions? We’ve got answers on our FAQ! 🔍" : "Questions? See FAQ! 🔎"}
                    icon={<EastOutlinedIcon sx={{ fontSize: '1rem' }} />}
                />
            </Box>
            <Box sx={{ mt: 1 }}>
                <CustomLink
                    to="/blog"
                    text={width >= breakpoint ? "Don’t miss out — explore our blog for the latest updates! 🚀" : "Blog for updates! 🚀"}
                    icon={<EastOutlinedIcon sx={{ fontSize: '1rem' }} />}
                />
            </Box>
        </Container>
    );
};

export default InformationSection;