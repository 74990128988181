// src/components/Faq.tsx
import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import MenuBar from './MenuBar';
import { Helmet } from 'react-helmet';
import InfoAccordion from './base/InfoAccordion';

const Faq: React.FC = () => {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.quirxy.me/faq" />
            </Helmet>
            {/* Use MenuBar component */}
            <MenuBar type="faq" />
            <Container maxWidth="sm" sx={{ py: 4, color: '#FFFFFF' }}>
                <Box sx={{ backgroundColor: 'transparent', border: '1px solid #FFFFFF', p: 4, borderRadius: 2 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Frequently Asked Questions
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{ color: '#FFFFFF', mb: 3 }}
                    >
                        We try to answer the most common questions about Qrixy here. If you have any other questions, feel free to contact us under <a href="mailto:coffeehouseworker@gmail.com" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>coffeehouseworker@gmail.com</a>.
                    </Typography>
                    <InfoAccordion />
                </Box>
            </Container>
        </>
    );
};

export default Faq;