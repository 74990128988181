// src/components/Impressum.tsx
import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import MenuBar from './MenuBar';
import { Helmet } from 'react-helmet';

const Impressum: React.FC = () => {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://www.quirxy.me/impressum" />
            </Helmet>
            {/* Use MenuBar component */}
            <MenuBar type="impressum" />

            <Container maxWidth="sm" sx={{ py: 4, color: '#FFFFFF' }}>
                <Box sx={{ backgroundColor: 'transparent', border: '1px solid #FFFFFF', p: 4, borderRadius: 2 }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Impressum
                    </Typography>
                    {/* Impressum Content */}
                    <Typography variant="body1" paragraph>
                        <strong>Website Owner:</strong> CoffeeHouseWorker
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Representative:</strong> Stephan Hoelscher
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Address:</strong><br />
                        Prehnsweg 15<br />
                        22143 Hamburg<br />
                        Germany
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Contact Information:</strong><br />
                        Email: <a href="mailto:coffeehouseworker@gmail.com" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>coffeehouseworker@gmail.com</a>
                    </Typography>
                </Box>
            </Container>
        </>
    );
};

export default Impressum;