// src/components/BlogPost.tsx
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

interface BlogPostProps {
    post: {
        title: string;
        datetime: string;
        content: string;
    };
}

const BlogPost: React.FC<BlogPostProps> = ({ post }) => {
    const formattedDate = new Date(post.datetime).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return (
        <Box sx={{ mb: 4 }}>
            <Card
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: '#FFFFFF',
                    borderRadius: 2,
                    boxShadow: 'none',
                    padding: 2,
                }}
            >
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {post.title}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom sx={{ opacity: 0.8 }}>
                        {formattedDate}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        {post.content}
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default BlogPost;