// src/analytics.ts
import ReactGA from "react-ga4";

export const initGA = () => {
  ReactGA.initialize("G-4B3MT9X613"); // Replace with your Measurement ID
};

export const logPageView = () => {
  ReactGA.send("pageview");
};
